import React, {useEffect, useState} from "react";
import {format} from "date-fns"
import {useNavigate, useParams} from "react-router-dom";


interface HeroCategory {
    id: number
    name: string
}

export type HeroProps = {
    nominee_name: string
    nominee_email?: string
    description?: string
    nominator_name?: string
    nominator_email?: string
    major?: string
    year?: string
    image?: string
    article_url?: string
    nomination_status?: string
    heroCategory: HeroCategory
}

const NominationForm = () => {
    const id = useParams();

    useEffect(() => {
        const getHero = async () => {
            const heroFromServer = await fetch(`https://heroes-api.apsoprojects.org/heroes/${id.id}`)
            const heroData = await heroFromServer.json()
            setHero(heroData)
            setPreview(heroData.image)
            setNomineeName(heroData.nominee_name)
            setNomineeEmail(heroData.nominee_email)
            setYear(heroData.year)
            setMajor(heroData.major)
            setDescription(heroData.description)
            setHeroCategory(heroData.heroCategory.id)
            setImg(heroData.image)
            setNominatorName(heroData.nominator_name)
            setNominatorEmail(heroData.nominator_email)
            setUrl(heroData.article_url)
            setNominationStatus(heroData.nomination_status)
            console.log(heroData)
        }
        const getHeroCategories = async () => {
            //frontend is using the award cats
            const categoriesFromServer = await fetch("https://heroes-api.apsoprojects.org/hero_categories")
            const categoryData = await categoriesFromServer.json()
            setCategories(categoryData)
        }
        getHero()
        getHeroCategories()
    }, [])

    const navigate = useNavigate()

    const handleDelete = async (e: { preventDefault: () => void; }) => {
        e.preventDefault()
        const confirm = window.confirm("are you sure you want to delete?")
        if (confirm) {
            await fetch(`https://heroes-api.apsoprojects.org/heroes/${id.id}`, {
                method: 'DELETE',
            }).then((res) => {
                if (res.status >= 400 && res.status < 600) {
                    alert("Bad response from server")
                } else {
                    navigate("/nominations")
                    return res.json()
                }
            })
        }
    }

    const [nominee_name, setNomineeName] = useState("")
    const [nominee_email, setNomineeEmail] = useState("")
    const [year, setYear] = useState("")
    const [major, setMajor] = useState("")
    const [description, setDescription] = useState("")
    const [heroCategory, setHeroCategory] = useState("")
    const [img, setImg] = useState<File | string>("")
    const [nominator_name, setNominatorName] = useState("")
    const [nomiation_status, setNominationStatus] = useState("")
    const [nominator_email, setNominatorEmail] = useState("")
    const [preview, setPreview] = useState('')
    const [majors, setMajors] = useState([])
    const [article_url, setUrl] = useState("")
    const [hero, setHero] = useState<HeroProps>()
    const [categories, setCategories] = useState([])
    return (
        <div>
            <div className="w-clearfix"><img src="../../public/images/Style-Bar.svg" loading="lazy" alt=""
                                             className="image-3"/>
            </div>
            <div className="w-form">
                <form id="email-form-6" name="email-form-6" className="form-6">
                    <h1>Nomination</h1>
                    <div className="text-block">Date Sumbitted: {format(Date.now(), "MM/dd/yyyy")}</div>
                    <div>
                        <div className="columns-2 w-row">
                            <div className="column-3 w-col w-col-9">
                                <div className="div-block-14">
                                    <div className="columns w-row">
                                        <div className="column w-col w-col-6"><label htmlFor="name"
                                                                                     className="form-field-headings">Nominator
                                            Name</label><input type="text" className="w-input" maxLength={256}
                                                               name="name" data-name="Name" placeholder="Jane Doe"
                                                               id="name" required={true}
                                                               defaultValue={hero?.nominator_name ? hero?.nominator_name : ""}
                                                               onChange={(e) => {
                                                                   setNominatorName(e.target.value);
                                                               }}/><label htmlFor="Nominee-Name-2"
                                                                          className="form-field-headings">Student
                                            Nominee Name</label><input type="text" className="w-input" maxLength={256}
                                                                       name="Nominee-Name" data-name="Nominee Name"
                                                                       placeholder="Joe Smith" id="Nominee-Name-2"
                                                                       required={true} defaultValue={hero?.nominee_name}
                                                                       onChange={(e) => {
                                                                           setNomineeName(e.target.value)
                                                                       }}/><label htmlFor="Student-Nominee-Year"
                                                                                  className="form-field-headings">Student
                                            Nominee Year</label><input type="text" className="w-input" maxLength={256}
                                                                       name="Student-Nominee-Year"
                                                                       data-name="Student Nominee Year"
                                                                       placeholder="Junior" id="Student-Nominee-Year"
                                                                       required={true} defaultValue={hero?.year}
                                                                       onChange={(e) => {
                                                                           setYear(e.target.value)
                                                                       }}/></div>
                                        <div className="column-2 w-col w-col-6"><label htmlFor="Nominator-Email"
                                                                                       className="form-field-headings">Nomiator
                                            Email</label><input type="email" className="w-input" maxLength={256}
                                                                name="Nominator-Email" data-name="Nominator Email"
                                                                placeholder="jdoe@bsu.edu" id="Nominator-Email"
                                                                required={true} defaultValue={hero?.nominator_email}
                                                                onChange={(e) => {
                                                                    setNominatorEmail(e.target.value)
                                                                }}/><label htmlFor="Nominee-Email"
                                                                           className="form-field-headings">Student
                                            Nominee Email</label><input type="email" className="w-input" maxLength={256}
                                                                        name="Nominee-Email" data-name="Nominee Email"
                                                                        placeholder="jsmith@bsu.du" id="Nominee-Email"
                                                                        required={true}
                                                                        defaultValue={hero?.nominee_email}
                                                                        onChange={(e) => {
                                                                            setNomineeEmail(e.target.value)
                                                                        }}/><label htmlFor="Nominee-Email-2"
                                                                                   className="form-field-headings">Student
                                            Nominee Major</label><input type="text" className="w-input" maxLength={256}
                                                                        name="Student-Nominee-Major"
                                                                        data-name="Student Nominee Major"
                                                                        placeholder="Media" id="Student-Nominee-Major"
                                                                        required={true} defaultValue={hero?.major}
                                                                        onChange={(e) => {
                                                                            setMajor(e.target.value)
                                                                        }}/></div>
                                    </div>
                                </div>
                                <label htmlFor="field-2" className="form-field-headings">Reason for
                                    Nomination</label><textarea placeholder="Example Text" maxLength={5000} id="field-2"
                                                                name="field-2" data-name="field"
                                                                className="textarea w-input"
                                                                defaultValue={hero?.description} onChange={(e) => {
                                setDescription(e.target.value)
                            }}></textarea>
                                <div className="w-row">
                                    <div className="w-col w-col-6"><label htmlFor="Nomination-Category"
                                                                          className="form-field-headings">Nomination
                                        Category</label><select id="field-4" name="field-4" data-name="Field 4"
                                                                required={true}
                                                                className="w-select" onChange={(e) => {
                                        setHeroCategory(e.target.value);
                                    }}>
                                        {categories && categories.map(({id, name}) => (
                                            <option selected={heroCategory === id ? true : false} value={id}>{name}</option>
                                        ))}


                                    </select><label
                                        htmlFor="Link-to-Highlight" className="form-field-headings">Link to Finished
                                        Highlight</label><input type="text" className="w-input" maxLength={256}
                                                                name="Link-to-Highlight" data-name="Link to Highlight"
                                                                placeholder="link.com" id="Link-to-Highlight"
                                                                defaultValue={hero?.article_url}
                                                                onChange={(e) => setUrl(e.target.value)}/></div>
                                    <div className="w-clearfix w-col w-col-6"><label htmlFor="Nominee-Name"
                                                                                     className="form-field-headings">Nominee
                                        Image</label><input type="file" accept={".heic/*,image/*"} className="w-input"
                                                            maxLength={256}
                                                            name="Nominee-Name" data-name="Nominee Name"
                                                            placeholder="filename.jpg" id="Nominee-Name"
                                                            onChange={(e) => {
                                                                if (e.target.files) {
                                                                    setImg(e.target.files[0])
                                                                    setPreview(URL.createObjectURL(e.target.files[0]))
                                                                }
                                                            }}/>
                                        <a href="#" target="_blank"
                                           className="button download w-button">Download</a><label
                                            className="field-label">Max File Size: 5MB. Image Dimensions: 1024 x
                                            768</label>
                                        {preview && <img className={'image-preview'} src={preview}
                                        />}
                                    </div>
                                </div>
                            </div>
                            <div className="w-col w-col-3">
                                <div className="div-block-15">
                                    <div className="div-block-18"><label htmlFor="field-3"
                                                                         className="form-field-headings white-text">Status</label><select
                                        id="field-3" name="field-3" data-name="Field 3" required={true}
                                        className="select-field w-select" onChange={(e) => {
                                        setNominationStatus(e.target.value)
                                    }}>
                                        <option value="">Select one...</option>
                                        <option value="In Progress"
                                                selected={nomiation_status === "In Progress" ? true : false}>In Progress
                                        </option>
                                        <option value="Complete"
                                                selected={nomiation_status === "Complete" ? true : false}>Completed
                                        </option>
                                        <option value="New"
                                                selected={nomiation_status === "New" ? true : false}>Available
                                        </option>
                                    </select>
                                        <ul role="list" className="list-3 w-list-unstyled">
                                            <li><input type="submit" value="Update" data-wait="Please wait..."
                                                       className="button red update w-button" onClick={async (e) => {
                                                e.preventDefault()
                                                const formData = new FormData()
                                                console.log("ITS SUBMITTING")
                                                formData.append("nominee_name", nominee_name)
                                                formData.append("nominee_email", nominee_email)
                                                formData.append("description", description)
                                                formData.append("nominator_email", nominator_email)
                                                formData.append("nominator_name", nominator_name)
                                                formData.append("major", major)
                                                formData.append("year", year)
                                                if (typeof img !== "string") {
                                                    formData.append("file", img)
                                                }
                                                formData.append('nomination_status', nomiation_status)
                                                formData.append("hero_category_id", heroCategory)
                                                formData.append("article_url", article_url)
                                                await fetch(`https://heroes-api.apsoprojects.org/heroes/${id.id}`, {
                                                    method: 'PUT',
                                                    body: formData
                                                }).then((res) => {
                                                    if (res.status >= 300 && res.status < 600) {
                                                        alert("Bad response from server")
                                                        console.log(img)
                                                        console.log(res.json())
                                                    } else {
                                                        console.log(img)
                                                        console.log(res.json())
                                                        alert("Success!")
                                                        // return res.json()
                                                    }
                                                })
                                            }}/></li>
                                            <li><input type="submit" value="Delete Nomination"
                                                       data-wait="Please wait..."
                                                       className="button delete-nomination w-button"
                                                       onClick={async (e) => {
                                                           handleDelete(e)
                                                       }}/></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
                <div className="w-form-done">
                    <div>Thank you! Your submission has been received!</div>
                </div>
                <div className="w-form-fail">
                    <div>Oops! Something went wrong while submitting the form.</div>
                </div>
            </div>
        </div>
    )
}
export default NominationForm