import React, {useEffect, useState} from "react";
import {format, parseISO} from "date-fns";

interface HeroCategory{
    id: number
    name: string
}

export type HeroProps ={
    nominee_name : string
    nominee_email ?: string
    description ?: string
    nominator_name ?: string
    nominator_email ?: string
    major ?: string
    year ?: string
    image ?: string
    article_url ?: string
    nomination_status ?: string
    heroCategory : HeroCategory
}

const Nominations = () => {
    const [nominations, setNominations] = useState([])
    const [page, setPage] = useState(2)
    const [noMoreAwards, setNoMoreAwards] = useState(false)

    useEffect(() => {
        const getNominations = async () => {
            const nominationsFromServer = await fetch("https://heroes-api.apsoprojects.org/heroes/?page=1")
            const nominationData = await nominationsFromServer.json()
            console.log(nominationData)
            const nominationFromServerNextPage = await fetch(`https://heroes-api.apsoprojects.org/heroes/?page=${page+1}`)
            const dataNextPage = await nominationFromServerNextPage.json()

            if(dataNextPage.data.length == 0) {
                setNoMoreAwards(true)
            }
            setNominations(nominationData.data)
        }
        getNominations()
    }, [])
    const addMoreNominations = async () => {
        const awardsFromServer = await fetch(`https://heroes-api.apsoprojects.org/heroes/?page=${page}`)
        const data = await awardsFromServer.json()
        setNominations(nominations.concat(data.data));

        const nominationFromServerNextPage = await fetch(`https://heroes-api.apsoprojects.org/heroes/?page=${page+1}`)
        const dataNextPage = await nominationFromServerNextPage.json()

        if(dataNextPage.data.length == 0) {
            setNoMoreAwards(true)
        }
        setPage(page + 1)
    };
    return (
        <div className="section-3 wf-section">
            {/*<div className="w-clearfix"><img src="../../public/images/Style-Bar.svg" loading="lazy" alt="" className="image-3"/>*/}
            {/*</div>*/}
            <div className="div-block-8 w-clearfix">
                <h3>Nominations</h3>
                <div className="div-block-7"><img src="../../public/images/Group-12.svg" loading="lazy" width="16" alt=""
                                                  className="image-4"/>
                </div>
            </div>
            <div className="div-block-11">
                <div className="div-block-10 table-titles">
                    <ul className="nomimation-titles w-list-unstyled">
                        <li className="nonimation-chart-titles">Date Submitted</li>
                        <li className="nonimation-chart-titles">Name</li>
                        <li className="nonimation-chart-titles">Major</li>
                        <li className="nonimation-chart-titles">Nomination Category</li>
                        <li className="nonimation-chart-titles">Status</li>
                    </ul>
                    <div className="div-block-12"></div>
                </div>
                <div className="div-block-10 white-row">
                    {nominations && nominations.map(({id, created_at, nominee_name, major, heroCategory: {name}, nomination_status}) => (
                        <a className={"award-page-link"} href={`/nomination/${id}`}>
                        <ul className="nomimation-titles content w-list-unstyled">
                            <li className="nomination-content">{format(parseISO(created_at), "MM/dd/yyyy")}</li>
                            <li className="nomination-content">{nominee_name}</li>
                            <li className="nomination-content">{major}</li>
                            <li className="nomination-content">{name}</li>
                            <li className="nomination-content">{nomination_status}</li>
                        </ul>
                        </a>
                    ))}
                </div>
                {!noMoreAwards &&
                <button className={'seeMoreButton'} onClick={addMoreNominations}>+ See more</button>
                }
            </div>
            <div className="div-block-13 awards-bottom-lines w-clearfix"><img src="../images/Style-Bar.svg"
                                                                              loading="lazy" alt=""
                                                                              className="image-5 awards-bottom-lines"/>
            </div>
        </div>
    )
}
export default Nominations