import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";

interface AwardCategory{
    name: string
}

interface AwardProps {
    award_name : string
    description ?: string
    start_date ?: Date
    end_date ?: Date
    image ?: string
    url ?: string
    award_contact_email ?: string
    is_featured : boolean
    award_category_id: number
    awardCategory: AwardCategory
    award_status ?: string
}

const UpdateAward = () => {
    const id = useParams();
    const [award, setAward] = useState<AwardProps>()
    const [preview, setPreview] = useState("")
    const [award_name, setAwardName] = useState("")
    const [description, setDescription] = useState("")
    const [start_date, setStartDate] = useState("")
    const [end_date, setEndDate] = useState("")
    const [image, setImage] = useState<File | string>("")
    const [url, setUrl] = useState("")
    const [award_contact_email, setContactEmail] = useState("")
    const [award_status, setAwardStatus] = useState("")
    const [awardCategory, setAwardCategory] = useState("")
    const [categories, setCategories] = useState([])
    useEffect(() => {
        const getAward = async () => {
                const awardFromServer = await fetch(`https://heroes-api.apsoprojects.org/awards/${id.id}`)
                const awardData = await awardFromServer.json()
                setAward(awardData)
                setPreview(awardData.image)
                setImage(awardData.image)
                setAwardCategory(awardData.award_category_id)
        }
        const getAwardCategories = async () => {
            const categoriesFromServer = await fetch("https://heroes-api.apsoprojects.org/award_categories")
            const categoryData = await categoriesFromServer.json()
            setCategories(categoryData)
        }
        getAward()
        getAwardCategories()
    }, [])

    useEffect(() => {
        if(award) {
            setAwardName(award.award_name)
            setDescription(award.description!)
            setStartDate(award.start_date!.toString())
            setEndDate(award.end_date!.toString())
            setContactEmail(award.award_contact_email!)
            // setAwardCategory(award.award_category_id.toString())
            setAwardStatus(award.award_status!)
        }
    }, [award])

    const navigate = useNavigate()


    const handleDelete = async (e: { preventDefault: () => void; }) => {
        e.preventDefault()
        const confirm = window.confirm("are you sure you want to delete?")
        if (confirm) {
            await fetch(`https://heroes-api.apsoprojects.org/awards/${id.id}`, {
                method: 'DELETE',
            }).then((res) => {
                if (res.status >= 400 && res.status < 600) {
                    alert("Bad response from server")
                } else {
                    navigate("/awards")
                    return res.json()
                }
            })
        }
    }



    const submit =  async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        const formData = new FormData()
        formData.append("award_name", award_name)
        formData.append("description", description)
        formData.append("start_date", start_date.toString())
        formData.append("end_date", end_date.toString())
        formData.append("url", url)
        formData.append("award_contact_email", award_contact_email)
        if (typeof image !== "string") {
            formData.append("file", image)
        }
        formData.append('award_status', award_status)
        formData.append("award_category_id", awardCategory)

        console.log("submit")
        await fetch(`https://heroes-api.apsoprojects.org/awards/${id.id}`, {
            method: 'PUT',
            body: formData
        }).then((res) => {
            if (res.status >= 400 && res.status < 600) {
                console.log(res.json())
                alert("Bad response from server")
            }
            else {
                navigate("/awards")
                console.log(res.json())
                return res.json()
            }
        })}
    return (
        <div>
            <div className="w-clearfix">
                <img src={"../../public/images/Style-Bar.svg"} loading="lazy" alt="" className="image-3"/>
            </div>
            <div className="w-form">
                <form id="email-form-6" name="email-form-6" className="form-6" onSubmit={submit}>
                    <h1>Update Award</h1>
                    <div>
                        <div className="columns-2 w-row">
                            <div className="column-3 w-col w-col-9">
                                <div className="div-block-14">
                                    <div className="columns w-row">
                                        <div className="column w-clearfix w-col w-col-6">
                                            <label htmlFor="name" className="form-field-headings">Award Name</label>
                                            <input type="text" className="w-input" maxLength={256} name="name"
                                                   data-name="Name" placeholder={"Jane Doe"} id="name" required={true} defaultValue={award?.award_name} onChange={(e) => {setAwardName(e.target.value)}}/>
                                            <label htmlFor="Nominee-Name-2" className="form-field-headings">Award
                                                Highlight Start Date</label>
                                            <input type="date" className="w-input" maxLength={256} name="Nominee-Name"
                                                   data-name="Nominee Name" placeholder="2/1/22" id="Nominee-Name-2"
                                                   required={true} defaultValue={award?.start_date ? award?.start_date.toString() : ""} onChange={(e) => {setStartDate(e.target.value)}}/>
                                            <label htmlFor="Student-Nominee-Year" className="form-field-headings">Link
                                                to Award Information</label>
                                            <input type="text" className="w-input" maxLength={256}
                                                   name="Student-Nominee-Year" data-name="Student Nominee Year"
                                                   placeholder="link.com" id="Student-Nominee-Year" required={true} defaultValue={award?.url ? award?.url : ""} onChange={(e) => {setUrl(e.target.value)}}/>
                                            <label htmlFor="Nominee-Name" className="form-field-headings">Award
                                                Image</label>
                                            <input type="file"  accept={".heic/*,image/*"} className="w-input" name="Nominee-Name"
                                                   data-name="Nominee Name" id="Nominee-Name" title={award?.image}
                                                   onChange={ (e) => {
                                                if (e.target.files){
                                                    setImage(e.target.files[0])
                                                    setPreview(URL.createObjectURL(e.target.files[0]))
                                                }
                                            }}/>
                                            <a href="#" target="_blank" className="button download w-button">Replace</a>
                                            <label className="field-label">Max File Size: 5MB. Image Dimensions: 1024 x
                                                768</label>
                                            {preview && <img className={'image-preview'} src={preview}/>}
                                        </div>
                                        <div className="column-2 w-col w-col-6">
                                            <label htmlFor="Nominator-Email" className="form-field-headings">Award
                                                Contact Email</label>
                                            <input type="email" className="w-input" maxLength={256}
                                                   name="Nominator-Email" data-name="Nominator Email"
                                                   placeholder="jdoe@bsu.edu" id="Nominator-Email" required={true} defaultValue={award?.award_contact_email ? award.award_contact_email : ""} onChange={(e) => {setContactEmail(e.target.value)}}/>
                                            <label htmlFor="Nominee-Email" className="form-field-headings">Award
                                                Highlight End Date</label>
                                            <input type="date" className="w-input" maxLength={256} name="Nominee-Email"
                                                   data-name="Nominee Email" placeholder="5/1/22" id="Nominee-Email"
                                                   required={true} defaultValue={award?.end_date ? award?.end_date.toString() : ""} onChange={(e) => {setEndDate(e.target.value)}}/>
                                            <label htmlFor="Award Description" className="form-field-headings">Award Description</label>
                                            <input type={"text"} className={"w-input"} maxLength={1000} name="Award Description" data-name="Award Description" placeholder={"Example text..."} defaultValue={award?.description ? award.description : ""} onChange={(e) => {setDescription(e.target.value)}}/>
                                            <label htmlFor="field-4" className="form-field-headings">Award
                                                Category</label>
                                            <select id="field-4" name="field-4" data-name="Field 4" required={true}
                                                    className="w-select" onChange={(e) => {
                                                setAwardCategory(e.target.value);
                                                console.log(e.target.value)
                                                console.log("I AM HERE")
                                            }}>
                                                {categories && categories.map(({id, name}) => (
                                                    <option selected={awardCategory === id ? true : false} value={id}>{name}</option>
                                                ))}


                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="w-col w-col-3">
                                <div className="div-block-15">
                                    <div className="div-block-18">
                                        <label htmlFor="field-3"
                                               className="form-field-headings white-text">Status</label>
                                        <select id="field-3" name="field-3" data-name="Field 3" required={true}
                                                className="select-field w-select" value={award_status} onChange={(e) => {setAwardStatus(e.target.value)}}>
                                            <option value="">Select one...</option>
                                            <option value="Inactive" selected={award_status === "Inactive" ? true : false}>Inactive</option>
                                            <option value="Active" selected={award_status === "Active" ? true : false}>Active</option>
                                        </select>
                                        <ul role="list" className="list-3 w-list-unstyled">
                                            <li><input type="submit" value="Update" data-wait="Please wait..."
                                                       className="button red update w-button"/></li>
                                            <li><input type="button" value="Delete Award"
                                                       data-wait="Please wait..."
                                                       className="button delete-nomination w-button" onClick={(e) => handleDelete(e)}/></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
                <div className="w-form-done">
                    <div>Thank you! Your submission has been received!</div>
                </div>
                <div className="w-form-fail">
                    <div>Oops! Something went wrong while submitting the form.</div>
                </div>
            </div>
        </div>
)
}

export default UpdateAward