import React, {useState} from "react";
import {ReactComponent} from "*.svg";
import {useNavigate} from "react-router-dom";

const SignIn = () => {
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const navigate = useNavigate()
    const login = async(e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        return fetch(`https://heroes-api.apsoprojects.org/admins/login`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                email: email,
                password: password
            })
        })
            .then(response => {
                if(response.ok) {
                    return response.json();
                }
                throw new Error("Invalid email or password");
            })
            .then(response => {
                sessionStorage.setItem("session_key", response.token);
                navigate("/nominations");
            })
            .catch(err => {
                sessionStorage.clear();
                window.alert(err);
            })
    }
    return (
        <section id="subscribe-form" className="subscribe-form wf-section">
            <div className="centered-container w-container">
                <h2>Administrative LogIn</h2>
                <div className="form-block w-form">
                    <form id="email-form" name="email-form"
                          className="subscribe-form-flex" onSubmit={login}>
                        <div className="subscribe-form-input-wrapper"><label htmlFor="Subscriber-Email"
                                                                             id="subscribe-email">Email
                            Address</label><input type="email" className="subscribe-form-input w-input" maxLength={256}
                                                  name="Subscriber-Email" data-name="Subscriber Email" placeholder=""
                                                  id="Subscriber-Email" required={true} onChange={(e) => {setEmail(e.target.value)}}/></div>
                        <div className="subscribe-form-input-wrapper"><label htmlFor="Subscriber-Email-2"
                                                                             id="subscribe-email">Password</label><input
                            type="password" className="subscribe-form-input w-input" maxLength={256}
                            name="Subscriber-Email-2" data-name="Subscriber Email 2" placeholder=""
                            id="Subscriber-Email-2" required={true} onChange={(e) => {setPassword(e.target.value)}}/></div>
                    </form>
                </div>
                <button type={"submit"} className="button red w-button" >LogIn</button>
                <p>If you need administrative access and do not have a log in, please contact [contact name] at [contact
                    email].</p>
            </div>
        </section>
    )
}
export default SignIn