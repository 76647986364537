import React, {useEffect, useState} from "react";

const Awards = () => {
    const [awards, setAwards] = useState([]);
    const [page, setPage] = useState(2);
    const [noMoreAwards, setNoMoreAwards] = useState(false)

    useEffect(() => {
        const getAwards = async () => {
            const awardsFromServer = await fetch("https://heroes-api.apsoprojects.org/allAwards/?page=1")
            const awardData = await awardsFromServer.json()
            const awardsFromServerNextPage = await fetch(`https://heroes-api.apsoprojects.org/allAwards/?page=${page+1}`)
            const dataNextPage = await awardsFromServerNextPage.json()

            if(dataNextPage.data.length == 0) {
                setNoMoreAwards(true)
            }
            setAwards(awardData.data)
        }
        getAwards()
    }, [])
    const addMoreAwards = async () => {
        const awardsFromServer = await fetch(`https://heroes-api.apsoprojects.org/allAwards/?page=${page}`)
        const data = await awardsFromServer.json()
        setAwards(awards.concat(data.data));

        const awardsFromServerNextPage = await fetch(`https://heroes-api.apsoprojects.org/allAwards/?page=${page+1}`)
        const dataNextPage = await awardsFromServerNextPage.json()

        if(dataNextPage.data.length == 0) {
            setNoMoreAwards(true)
        }
        setPage(page + 1)
    };
    return (
        <div>
            <div className="section-3 wf-section">
                <div className="w-clearfix">
                    <a href="/add-award" className="button w-button">Add New Award</a>
                </div>
                <div className="div-block-8 w-clearfix">
                    <h3>Awards</h3>
                    <div className="div-block-7"><img src="../../public/images/Group-12.svg" loading="lazy" width="16" alt=""
                                                      className="image-4"/>
                    </div>
                </div>
                <div className="div-block-11">
                    <div className="div-block-10 table-titles">
                        <ul className="nomimation-award-titles w-list-unstyled">
                            <li className="nonimation-chart-titles">Award Name</li>
                            <li className="nonimation-chart-titles">Description</li>
                            <li className="nonimation-chart-titles">Start Date</li>
                            <li className="nonimation-chart-titles">End Date</li>
                            <li className="nonimation-chart-titles">Link</li>
                            <li className="nonimation-chart-titles">Status</li>
                        </ul>
                        <div className="div-block-12"></div>
                    </div>
                    <div className="div-block-10 white-row">
                            {awards && awards.map(({id, description, award_name, start_date, end_date, url, award_status}) => (
                            <a className={"award-page-link"} href={`/update-award/${id}`}>
                                <ul role="list" className="nomimation-award-titles content w-list-unstyled">
                                    <li className="award-content award-name">{award_name}</li>
                                    <li className="award-content" id={"award-description"}>{description}</li>
                                    <li className="award-content" id={"award-start"}>{start_date}</li>
                                    <li className="award-content" id={"award-end"}>{end_date}</li>
                                    <li className="award-content" id={"award-url"}>{url}</li>
                                    <li className="award-content" id={"award-status"}>{award_status}</li>
                                </ul>
                            </a>
                            ))}
                    </div>
                    {!noMoreAwards &&
                    <button className={'seeMoreButton'} onClick={addMoreAwards}>+ See more</button>
                    }
                </div>
            </div>
            <div className="div-block-13 awards-bottom-lines w-clearfix"><img src="../images/Style-Bar.svg"
                                                                              loading="lazy" alt=""
                                                                              className="image-5 awards-bottom-lines"/>
            </div>
        </div>
    )
}
export default Awards