import React from 'react';
import {Routes, Route, BrowserRouter} from "react-router-dom";
import './App.css';
import UpdateAward from "./pages/UpdateAward";
import AddAward from "./pages/AddAward";
import Header from "./components/Header";
import Footer from "./components/Footer";
import SignIn from "./pages/SignIn";
import Testimonials from "./pages/Testimonials";
import Nominations from "./pages/Nominations";
import NominationForm from "./pages/NominationForm";
import Awards from "./pages/Awards";

function App() {
  return (
    <div className="App">
        <BrowserRouter>
            <Header/>
            <Routes>
                <Route path={"/update-award/:id"} element={<UpdateAward />}/>
                <Route path={"/add-award"} element={<AddAward />}/>
                <Route path={"/awards"} element={<Awards/>}/>
                <Route path={"/sign-in"} element={<SignIn/>} />
                <Route path={"/testimonials"} element={<Testimonials/>}/>
                <Route path={"/nominations"} element={<Nominations/>}/>
                <Route path={"/nomination/:id"} element={<NominationForm/>}/>
            </Routes>
            <Footer />
        </BrowserRouter>
    </div>
  );
}

export default App;
