import React from "react";
import {useLocation} from "react-router-dom";

const Footer = () => {
    const location  = useLocation()
    if(location.pathname === "/sign-in"){
        return(
            <footer id="nav" className="sticky-nav footer admin">
                <nav className="w-container">
                    <ul className="nav-grid w-list-unstyled">
                        <li id="w-node-f5b9bf12-df94-5ba8-449b-06e923f3f2f4-23f3f2f1" className="w-clearfix">
                            <a href="https://heroes.apsoprojects.org/nominate" className="button white w-button">Nominate</a>
                        </li>
                        <li>
                            <a href="https://heroes.apsoprojects.org/" className="nav-link admin">Home</a>
                        </li>
                        <li>
                            <a href="https://heroes.apsoprojects.org/about" className="nav-link admin">About</a>
                        </li>
                        <li>
                            <a href="https://heroes.apsoprojects.org/heroes" className="nav-link admin">Heroes</a>
                        </li>
                        <li>
                            <a href="https://heroes.apsoprojects.org/awards" className="nav-link admin">Awards</a>
                        </li>
                        <li>
                            <a href="/sign-in" className="nav-link admin">Admin Login</a>
                        </li>
                    </ul>
                </nav>
            </footer>
        )
    }
    else {
        return null
    }
}
export default Footer