import React, {useEffect, useState} from "react";
import {format} from "date-fns";
import { useNavigate } from "react-router-dom";

const AddAward = () => {
    const [award_name, setAwardName] = useState("");
    const [description, setDescription] = useState("")
    const [start_date, setStartDate] = useState<string>(Date.now().toString())
    const [end_date, setEndDate] = useState<string>("")
    const [image, setImage] = useState<File | string>("")
    const [url, setUrl] = useState("")
    const [award_contact_email, setAwardEmail] = useState("")
    const [awardCategory, setAwardCategory] = useState("")
    const [preview, setPreview] = useState<string>()
    const [award_status, setAwardStatus] = useState("")
    const [categories, setCategories] = useState([])
    useEffect(() => {
        const getAwardCategories = async () => {
            const categoriesFromServer = await fetch("https://heroes-api.apsoprojects.org/award_categories")
            const categoryData = await categoriesFromServer.json()
            setCategories(categoryData)
            setAwardCategory(categoryData[0].id)
        }
        getAwardCategories()
    }, [])

    const navigate = useNavigate()
    const submit =  async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        const formData = new FormData()
        formData.append("award_name", award_name)
        formData.append("description", description)
        formData.append("start_date", start_date.toString())
        formData.append("end_date", end_date.toString())
        formData.append("url", url)
        formData.append("award_contact_email", award_contact_email)
        if (typeof image !== "string") {
            formData.append("file", image)
        }
        formData.append('award_status', award_status)
        formData.append("award_category_id", awardCategory)
        await fetch(`https://heroes-api.apsoprojects.org/awards`, {
            method: 'POST',
            body: formData
        }).then((res) => {
            if (res.status >= 400 && res.status < 600) {
                console.log(res.json())
                alert("Bad response from server")
            }
            else {
                console.log(res.json())
                navigate("/awards")
                return res.json()
            }
        })}

    return (
        <div>
            <div className="w-clearfix"><img src={"../../public/images/Style-Bar.svg"} loading="lazy" alt="" className="image-3"/>
            </div>
            <div className="w-form">
                <form id="email-form-6" name="email-form-6" data-name="Email Form 6" method="get" className="form-6" onSubmit={submit}>
                    <h1>Add Award</h1>
                    <div className="text-block">Date Sumbitted: {format(Date.now(), "MM/dd/yyyy")}</div>
                    <div>
                        <div className="columns-2 w-row">
                            <div className="column-3 w-clearfix w-col w-col-9">
                                <div className="div-block-14">
                                    <div className="columns w-row">
                                        <div className="column w-col w-col-6"><label htmlFor="name"
                                                                                     className="form-field-headings">Award
                                            Name</label><input type="text" className="w-input" maxLength={256}
                                                               name="name" data-name="Name" placeholder="Jane Doe"
                                                               id="name" required={true} onChange={(e) => {setAwardName(e.target.value)}}/><label htmlFor="Nominee-Name-2"
                                                                                            className="form-field-headings">Award
                                            Highlight Start Date</label><input type="date" className="w-input"
                                                                               maxLength={256} name="Nominee-Name"
                                                                               data-name="Nominee Name"
                                                                               placeholder="2/1/22" id="Nominee-Name-2"
                                                                               required={true} onChange={(e) => {setStartDate(e.target.value)}}/><label
                                            htmlFor="Student-Nominee-Year" className="form-field-headings">Link to Award
                                            Information</label><input type="text" className="w-input" maxLength={256}
                                                                      name="Student-Nominee-Year"
                                                                      data-name="Student Nominee Year"
                                                                      placeholder="link.com" id="Student-Nominee-Year"
                                                                      required={true} onChange={(e) => {setUrl(e.target.value)}}/>
                                            <label htmlFor="Nominee-Name" className="form-field-headings">Award
                                                Image</label><input type="file" className="w-input" maxLength={256}
                                                                    name="Nominee-Name" data-name="Nominee Name"
                                                                    placeholder="FileName_FirstName_LastName.jpg" id="Nominee-Name"
                                                                    required={true} onChange={ (e) => {
                                                if (e.target.files){
                                                    setImage(e.target.files[0])
                                                    setPreview(URL.createObjectURL(e.target.files[0]))
                                                }
                                            }}/></div>
                                        <div className="column-2 w-col w-col-6"><label htmlFor="Nominator-Email"
                                                                                       className="form-field-headings">Award
                                            Contact Email</label><input type="email" className="w-input" maxLength={256}
                                                                        name="Nominator-Email"
                                                                        data-name="Nominator Email"
                                                                        placeholder="jdoe@bsu.edu" id="Nominator-Email"
                                                                        required={true} onChange={(e) => {setAwardEmail(e.target.value)}}/><label htmlFor="Nominee-Email"
                                                                                           className="form-field-headings">Award
                                            Highlight End Date</label><input type="date" className="w-input"
                                                                             maxLength={256} name="Nominee-Email"
                                                                             data-name="Nominee Email"
                                                                             placeholder="5/1/22" id="Nominee-Email"
                                                                             required={true} onChange={(e) => {setEndDate(e.target.value)}}/>
                                            <label htmlFor="Award Description" className="form-field-headings">Award Description</label>
                                            <input required type={"text"} className={"w-input"} maxLength={1000} name="Award Description" data-name="Award Description" placeholder={"Example text..."} onChange={(e) => {setDescription(e.target.value)}}/>
                                            <label htmlFor="field-4"
                                                   className="form-field-headings">Award
                                                Category</label>
                                            <select id="field-4" name="field-4" data-name="Field 4"
                                                                    required={true} className="w-select" onChange={(e) => {setAwardCategory(e.target.value); console.log(awardCategory)}}>
                                            {categories && categories.map(({id, name}) => (
                                                <option value={id}>{name}</option>
                                            ))}
                                        </select></div>
                                    </div>
                                </div>
                                <div></div>
                                <a href="#" target="_blank" className="button download w-button">Download</a><label
                                className="field-label">Max File Size: 5MB. Image Dimensions: 1024 x 768</label>
                                {preview && <img className={'image-preview'} src={preview}/>}
                            </div>
                            <div className="w-col w-col-3">
                                <div className="div-block-15">
                                    <div className="div-block-18"><label htmlFor="field-3"
                                                                         className="form-field-headings white-text">Status</label><select
                                        id="field-3" name="field-3" data-name="Field 3" required={true}
                                        className="select-field w-select" onChange={(e) => {setAwardStatus(e.target.value)}}>
                                        <option value="">Select one...</option>
                                        <option value="Inactive">Inactive</option>
                                        <option value="Active">Active</option>
                                    </select>
                                        <ul role="list" className="list-3 w-list-unstyled">
                                            <li><input type="submit" value="Update" data-wait="Please wait..."
                                                       className="button red update w-button"/></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
                <div className="w-form-done">
                    <div>Thank you! Your submission has been received!</div>
                </div>
                <div className="w-form-fail">
                    <div>Oops! Something went wrong while submitting the form.</div>
                </div>
            </div>
        </div>
    )
}
export default AddAward