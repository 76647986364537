import React from "react";
import {NavLink, useLocation} from "react-router-dom";

const Header = () => {
    const location = useLocation()
    if(location.pathname === "/sign-in"){
        return(
            <header id="nav" className="sticky-nav admin">
                <nav className="w-container">
                    <ul className="nav-grid w-list-unstyled">
                        <li id="w-node-d25104ad-fa80-9a73-4314-001a5a604469-5a604466">
                            <a href="https://heroes.apsoprojects.org/" className="nav-logo-link"><img src={"../../public/images/Asset-3.svg"} alt=""
                                                                       className="nav-logo"/></a>
                        </li>
                        <li className="admin">
                            <a href="https://heroes.apsoprojects.org/" className="nav-link admin">Home</a>
                        </li>
                        <li>
                            <a href="https://heroes.apsoprojects.org/about" className="nav-link admin">About</a>
                        </li>
                        <li>
                            <a href="https://heroes.apsoprojects.org/heroes" className="nav-link admin">Heroes</a>
                        </li>
                        <li>
                            <a href="https://heroes.apsoprojects.org/awards" className="nav-link admin">Awards</a>
                        </li>
                        <li className="w-clearfix">
                            <a href="https://heroes.apsoprojects.org/nominate" className="button white w-button">Nominate</a>
                        </li>
                    </ul>
                </nav>
            </header>
        )
    }
    return (
        <header id="nav" className="sticky-nav footer admin admin-nav-signed-in">
            <nav className="w-container">
                <ul role="list" className="list w-list-unstyled">
                    <li className="list-item"><NavLink to={"nominations"} className={({isActive}) => (isActive ? "nav-link-admin-active" : "nav-link-admin")}>Nominations</NavLink></li>
                    <li className="list-item-2"><NavLink to={"awards"} className={({isActive}) => (isActive ? "nav-link-admin-active" : "nav-link-admin")}>Awards</NavLink></li>
                    <li className="list-item-3"><NavLink to={"testimonials"} className={({isActive}) => (isActive ? "nav-link-admin-active" : "nav-link-admin")}>Testimonials</NavLink></li>
                </ul>
            </nav>
        </header>
    )

}
export default Header