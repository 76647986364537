import React, {useEffect, useState} from "react";


const Testimonials = () => {
    const [testimonials, setTestimonials] = useState([])
    const [newQuote, setQuote] = useState("")
    const [newName, setName] = useState("")
    const [newTitle, setTitle] = useState("")
    useEffect(() => {
        const getTestimonials = async () => {
            const testimonials = await fetch("https://heroes-api.apsoprojects.org/testimonials")
            const testimonialData = await testimonials.json()
            setTestimonials(testimonialData)
        }
        getTestimonials()
    }, [])

    return (
        <div className="section-2 wf-section">
            <div className="w-form">
                <h1 className="heading-12">Testimonials</h1>
                {testimonials && testimonials.map(({quote, name, id, title}) => (
                    <form id="email-form-2" name="email-form-2" data-name="Email Form 2"
                          className="w-clearfix">
                        <div className="testimonial w-clearfix"><label htmlFor="field"
                                                                       className="heading-5">Quote</label><textarea
                            defaultValue={quote ? quote : ""}
                            maxLength={5000} id="field" name="field" data-name="field"
                            className="textarea-2 w-input"
                            onChange={(e) => {
                                setQuote(e.target.value)
                            }}></textarea><label htmlFor="Name"
                                                 className="heading-5">Name</label><input
                            type="text" className="text-field-3 w-input" maxLength={256} name="Name" data-name="Name"
                            id="Name" required={true} defaultValue={name ? name : "Jane Doe"} onChange={(e) => {
                            setName(e.target.value)
                        }}/>
                            <label>Title</label>
                            <input type={"text"} id={"Title"} name={"Title"} data-name={"Title"} className={"text-field-3 w-input"} required={true} defaultValue={title ? title : "Title"} onChange={(e) => {
                                setTitle(e.target.value)
                            }}/>
                            {/*    <input type="submit" value="Delete Testimonial"*/}
                            {/*           data-wait="Please wait..."*/}
                            {/*           className="button red delete w-button" onClick={async (e) => {*/}
                            {/*    e.preventDefault()*/}
                            {/*    const confirm = window.confirm("are you sure you want to delete?")*/}
                            {/*    if (confirm) {*/}
                            {/*        await fetch(`https://heroes-api.apsoprojects.org/testimonials/${id}`, {*/}
                            {/*            method: 'DELETE',*/}
                            {/*        }).then((res) => {*/}
                            {/*            if (res.status >= 400 && res.status < 600) {*/}
                            {/*                alert("Bad response from server")*/}
                            {/*            } else {*/}
                            {/*                window.location.reload();*/}
                            {/*                return res.json()*/}
                            {/*            }*/}
                            {/*        })*/}
                            {/*    }*/}

                            {/*}}/>*/}
                            <ul role="list" className="list-2 admin-testimonials w-list-unstyled">
                                <li className="list-item-4">
                                    <button className="button red w-button" onClick={async (e) => {
                                        e.preventDefault()
                                        const formData = new FormData()
                                        formData.append("quote", newQuote ? newQuote : quote)
                                        formData.append("name", newName ? newName : name)
                                        formData.append("title", newTitle ? newTitle : title)
                                        await fetch(`https://heroes-api.apsoprojects.org/testimonials/${id}`, {
                                            method: 'PUT',
                                            body: formData
                                        }).then((res) => {
                                            if (res.status >= 400 && res.status < 600) {
                                                alert("Bad response from server")
                                            } else {
                                                window.location.reload();
                                                return res.json()
                                            }
                                        })
                                    }}>Update Testimonial
                                    </button>
                                </li>
                                <li className="w-clearfix">
                                    <a href="#" className="button white black-outline w-button">Cancel</a>
                                </li>
                            </ul>

                        </div>
                    </form>
                ))}
                <div className="w-form-done">
                    <div>Thank you! Your submission has been received!</div>
                </div>
                <div className="w-form-fail">
                    <div>Oops! Something went wrong while submitting the form.</div>
                </div>
            </div>
            <div className="red-lines-right-div w-clearfix"><img src="../../public/images/Style-Bar.svg" loading="lazy"
                                                                 alt=""
                                                                 className="image-2"/></div>
        </div>
    )
}
export default Testimonials